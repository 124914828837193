import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{"margin": "0"}}>Julie Lee</h1>
        <p style={{"marginBottom": "4rem"}}>
          Software Developer<br />
          <a
            href="mailto:julie@haejulee.com"
            className="App-link"
          >
            julie@haejulee.com
          </a>
        </p>
        <a
          href="http://about.haejulee.com"
          target="_blank" rel="noreferrer"
          className="App-link"
        >
          About
        </a>
        <a
          href="https://github.com/haejulee"
          target="_blank" rel="noreferrer"
          className="App-link"
        >
          Github
        </a>
        <a
          href="https://www.linkedin.com/in/haejulee/"
          target="_blank" rel="noreferrer"
          className="App-link"
        >
          LinkedIn
        </a>
      </header>
    </div>
  );
}

export default App;
